import { useEffect, useState } from 'react';
import {SearchCriteria, SearchLocation} from '../model/SearchCriteria';
import styles from './SearchChart.module.css'

import { Checkbox, Input, Select } from 'antd';
import { stringify } from 'querystring';
import { IInputOption } from '../../../services/DashboardService';

interface IParams{
    onSearch: Function;
    onLocationChange: Function;
    searchCriteria: SearchCriteria | null;
    locationOptions: IInputOption[];
    isLoading: boolean;
}

const SearchChart = (props: IParams) => {
    const monthCriteria: {label: string, value: string}[] = [
        {label: 'มกราคม',   value: '01'},
        {label: 'กุมภาพันธ์',  value: '02'},
        {label: 'มีนาคม',    value: '03'},
        {label: 'เมษายน',   value: '04'},
        {label: 'พฤษภาคม',  value: '05'},
        {label: 'มิถุนายน',   value: '06'},
        {label: 'กรกฎาคม',  value: '07'},
        {label: 'สิงหาคม',   value: '08'},
        {label: 'กันยายน',   value: '09'},
        {label: 'ตุลาคม',    value: '10'},
        {label: 'พฤศจิกายน', value: '11'},
        {label: 'ธันวาคม',   value: '12'},
    ];
    const locationOptions: {label: string, value: string}[] = [
        {label: 'ทั้งหมด', value: 'ALL'},
        {label: 'บ้านพักค.เคหะ', value: '1'},
        {label: 'สันป่าเลี้ยง', value: '2'},
        {label: 'ตึกป่าพร้าวนอก', value: '3'},
        {label: 'พื้นที่เช่ารัตนโกสินทร์', value: '4'}
    ];
    const yearOptions: {label: string, value: string}[] = [
        {label: '2022', value: '2022'},
        {label: '2021', value: '2021'},
        {label: '2020', value: '2020'},
        {label: '2019', value: '2019'},
        {label: '2018', value: '2018'},
    ];
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(
        null
        // {
        //     year: '',
        //     fromMonth: '',
        //     toMonth: '',
        //     locationList: []
        // }
    );
    const [locationValues, setLocationValues] = useState<string[]>([]);
    const [searchYear, setSearchYear] = useState<string>();
    const [searchFromMonth, setSearchFromMonth] = useState<string>();
    const [searchToMonth, setSearchToMonth] = useState<string>();
    const [locationOptionsState, setLocationOptionState] = useState<IInputOption[]>(props.locationOptions);
    const [yearOptionsState, setYearOptionsState] = useState<IInputOption[]>(yearOptions);
    const { Option } = Select;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        initSearchCriteria();
    }, []);

    useEffect(() => {
        onSearch();
    }, [searchCriteria])

    useEffect(() => {
        updateCurrentCriteria();
    }, [searchYear])
    useEffect(() => {
        updateCurrentCriteria();
    }, [searchFromMonth])
    useEffect(() => {
        updateCurrentCriteria();
    }, [searchToMonth])
    useEffect(() => {
        // updateCurrentCriteria();
        // onLocationChange(locationValues);
        props.onLocationChange(locationValues);
    }, [locationValues])
    useEffect(() => {
        let allLocation = locationOptionsState.map((locationOption, index) => {
            return locationOption.value;
        });
        setLocationValues(allLocation);
    }, [locationOptionsState])
    useEffect(() => {
        setLocationOptionState(props.locationOptions);
    }, [props.locationOptions])
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    const updateCurrentCriteria = () => {
        if(searchYear && searchFromMonth && searchToMonth){
            setSearchCriteria(
                {
                    from: {
                        year: searchYear,
                        month: searchFromMonth
                    },
                    to: {
                        year: searchYear,
                        month: searchToMonth
                    },
                    // locationList: locationValues
                }
            );
        }
    }
    const initSearchCriteria = () => {
        let currentDate: Date = new Date();
        let currentYear: string = currentDate.getFullYear().toString();
        let currentMonth: string = (currentDate.getMonth() + 1).toString();

        setSearchYear(currentYear);
        setSearchFromMonth(currentMonth.length == 1 ? "0" + currentMonth : currentMonth);
        setSearchToMonth(currentMonth.length == 1 ? "0" + currentMonth : currentMonth);
        setLocationValues([]);

        // setSearchCriteria(
        //     {
        //         year: currentYear,
        //         fromMonth: currentMonth,
        //         toMonth: currentMonth,
        //         locationList: []
        //     }
        // );
    }

    const onSearch = () => {
        // console.log("onSearch");
        props.onSearch(searchCriteria);
    }
    const fromMonthChange = (selectedValue: any) => {
        setSearchFromMonth(selectedValue);
        if(searchToMonth && selectedValue > searchToMonth){
            setSearchToMonth(selectedValue);
        }
    }
    const toMonthChange = (selectedValue: any) => {
        setSearchToMonth(selectedValue);
        if(searchFromMonth && selectedValue < searchFromMonth){
            setSearchFromMonth(selectedValue);
        }
    }
    const yearChange = (selectedValue: any) => {
        setSearchYear(selectedValue);
    }

    const buildAllValue = () => {
        let result: string[] = [];
        for(let i = 0; i < locationOptionsState.length; i++){
            result.push(locationOptionsState[i].value);
        }
        return result;
    }
    const onLocationChange = (checkedValues: any[]) => {
        console.log("checkedValues: ", checkedValues);
        console.log("locationValues: ", locationValues);
        if(checkedValues.length > locationValues.length){
            onCheck(checkedValues);
        }else{
            onUnCheck(checkedValues);
        }
    }
    const onCheck = (checkedValues: any[]) => {
        let allValue = checkedValues.find((checkValue, index) => {
            return checkValue == "ALL";
        })
        console.log("allValue: ", allValue);
        if(allValue != null){
            setLocationValues(buildAllValue());
        }else{
            setLocationValues(checkedValues);
        }
    }
    const onUnCheck = (checkedValues: any[]) => {
        let findLocationAllValue = locationValues.find((value, index) => {
            return value == "ALL";
        });
        let findCheckedAllValue = checkedValues.find((value, index) => {
            return value == "ALL";
        });
        if(findLocationAllValue != null && findCheckedAllValue == null){
            setLocationValues([]);
        }else{
            let filterValues = checkedValues.filter((checkedValue, index) => {
                return checkedValue != "ALL";
            });
            setLocationValues(filterValues);
        }
    }
    return (
        <div className={styles["searchChartContainer"]}>
            <div className={styles["dateCriteriaContainer"]}>
                <div className={styles["searchSelectContainer"]}>
                    <div className={styles["labelContainer"]}>
                        ปี : 
                    </div>
                    <div className={styles["selectContainer"]}>
                        <Select 
                            className={styles["searchSelect"]} 
                            value={searchYear}
                            onChange={yearChange}>
                            {yearOptionsState.map((year, index) => {
                                return (
                                    <Option key={`year_${index}`} value={year.value}>{year.label}</Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className={styles["searchSelectContainer"]}>
                    <div className={styles["labelContainer"]}>
                        เดือน จาก : 
                    </div>
                    <div className={styles["selectContainer"]}>
                        <Select 
                            value={searchFromMonth}
                            className={styles["searchSelect"]}
                            onChange={fromMonthChange}>
                            {monthCriteria.map((month, index) => {
                                return (
                                    <Option disabled={false} key={`fromMonth_${index}`} value={month.value}>{month.label}</Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className={styles["searchSelectContainer"]}>
                    <div className={styles["labelContainer"]}>
                        ถึง : 
                    </div>
                    <div className={styles["selectContainer"]}>
                        <Select 
                            value={searchToMonth}
                            className={styles["searchSelect"]}
                            onChange={toMonthChange}>
                            {monthCriteria.map((month, index) => {
                                return (
                                    <Option key={`toMonth_${index}`} value={month.value}>{month.label}</Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles["locationCriteriaContainer"]}>
                <div className={styles['locationLabel']}>
                    สถานที่เช่า : {locationValues.length == locationOptionsState.length ? locationValues.length - 1 : locationValues.length}
                </div>
                <div className={styles['']}>
                    <Checkbox.Group style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: 300}} options={locationOptionsState} onChange={onLocationChange} value={locationValues} />
                </div>
            </div>
        </div>
    );
}

export default SearchChart;