export interface PeriodDate{
    fromDate: string | null;
    toDate: string |  null;
}

export const convertFromTo = (year: string, fromMonth: string, toMonth: string) => {
    let firstDateFrom = new Date(parseInt(year), parseInt(fromMonth) - 1, 1);
    let lastDateTo = new Date(parseInt(year), parseInt(toMonth) - 1, 1);
    console.log("convertFromTo: ", {firstDateFrom, lastDateTo, fromDateStr: (year + fromMonth + '01'), toDateStr: (year + toMonth + '01')})
    lastDateTo.setMonth(lastDateTo.getMonth() + 1);
    lastDateTo.setDate(lastDateTo.getDate() - 1);
    return {fromDate: convertDate2DBDateStr(firstDateFrom), toDate: convertDate2DBDateStr(lastDateTo)}
}

const strFormat = (value: string) => {
    return value.length > 1 ? value : "0" + value;
}

export const convertDate2DBDateStr = (date: Date) => {
    if(date == undefined || date == null){
        return null;
    }
    let monthVal = strFormat((date.getMonth() + 1).toString());
    let dateVal = strFormat(date.getDate().toString());
    let yearVal = date.getFullYear();
    
    return yearVal + monthVal + dateVal;
}

export const convertDate2DBTimeStr = (date: Date) => {
    if(date == undefined || date == null){
        return null;
    }
    let sec = strFormat(date.getSeconds().toString());
    let min = strFormat(date.getMinutes().toString());
    let hour = strFormat(date.getHours().toString());
    
    return hour + min + sec;
}

export const convert2ShowDate = (oldDate: string) => {
    let year = oldDate.substring(0, 4);
    let month = oldDate.substring(4, 6);
    let date = oldDate.substring(6, 8);
    let yearInt = parseInt(year);
    yearInt = yearInt < 2500 ? yearInt + 543 : yearInt;
    return date + "/" + month + "/" + yearInt;

}

export const getMonthName = (value: string) => {
    switch(value) {
        case '01': return "มกราคม";
        case '02': return "กุมภาพันธ์";
        case '03': return "มีนาคม";
        case '04': return "เมษายน";
        case '05': return "พฤษภาคม";
        case '06': return "มิถุนายน";
        case '07': return "กรกฎาคม";
        case '08': return "สิงหาคม";
        case '09': return "กันยายน";
        case '10': return "ตุลาคม";
        case '11': return "พฤศจิกายน";
        case '12': return "ธันวาคม";
    }
    return "ไม่ระบุ"
}