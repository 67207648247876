import React, { useEffect, useState } from "react";
import { Line, Bar, Column } from '@ant-design/charts';
import TextChart from "../component/TextChart";
import SummaryChart from "../component/SummaryChart";
import styles from '../Dashboard.module.css'
import SearchChart from "../component/SearchChart";
import { SearchCriteria, SearchLocation } from "../model/SearchCriteria";
import TopChart from "../component/TopChart";
import { PeriodDate } from "../../../helper/DateHelper";
import * as DateHelper from "../../../helper/DateHelper";
import { IDashboardData, DashboardRawData, getDashboardRawData, ISummaryChartData, ITextChartData, ITopChartData, adjustDashboardData, IInputOption, getLocation, getCurrentMonthDashboardRawData } from "../../../services/DashboardService";

function TransportTab() {
  const data = [
    { year: '1991', value: 3, type: 'ยอดชำระแล้ว' },
    { year: '1992', value: 4, type: 'ยอดชำระแล้ว' },
    { year: '1993', value: 3.5, type: 'ยอดชำระแล้ว' },
    { year: '1994', value: 5, type: 'ยอดชำระแล้ว' },
    { year: '1995', value: 4.9, type: 'ยอดชำระแล้ว' },
    { year: '1996', value: 6, type: 'ยอดชำระแล้ว' },
    { year: '1997', value: 7, type: 'ยอดชำระแล้ว'},
    { year: '1998', value: 9, type: 'ยอดชำระแล้ว' },
    { year: '1999', value: 13, type: 'ยอดชำระแล้ว' },


    { year: '1991', value: 8, type: 'ยอดคงค้าง' },
    { year: '1992', value: 2, type: 'ยอดคงค้าง' },
    { year: '1993', value: 13.5, type: 'ยอดคงค้าง' },
    { year: '1994', value: 9, type: 'ยอดคงค้าง' },
    { year: '1995', value: 8.5, type: 'ยอดคงค้าง' },
    { year: '1996', value: 11, type: 'ยอดคงค้าง' },
    { year: '1997', value: 1, type: 'ยอดคงค้าง'},
    { year: '1998', value: 5, type: 'ยอดคงค้าง' },
    { year: '1999', value: 7, type: 'ยอดคงค้าง' },
  ];

  const config = {
    autoFit: true,
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  let chart: any;

  const bussinessId = 2;

  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(null);
  const [rawData, setRawData] = useState<DashboardRawData>();
  const [dashboardData, setDashboardData] = useState<IDashboardData>();
  const [dashboardRawData, setDashboardRawData] = useState<DashboardRawData[]>();
  const [currMonthDashboardRawData, setCurrMonthDashboardRawData] = useState<DashboardRawData[]>([]);
  const [searchLocationList, setSearchLocationList] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<IInputOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentMonth, setCurrentMonth] = useState<string>("01");
  const [currentMonthLabel, setCurrentMonthLabel] = useState<string>("");

  const [textChartData, setTextChartData] = useState<ITextChartData>({
        totalIncome: 0,
        paid: 0,
        overDue: 0,
        totalRoom: 0,
        rentalRoom: 0,
        emptyRoom: 0,
  });
  const [topChartData, setTopChartData] = useState<ITopChartData>({
      income: [],
      paid: [],
      overdue: [],
      rented: [],
      freeRoom: []
  });
  const [summaryChartData, setSummaryChartData] = useState<ISummaryChartData>({summaryDataList: []});

  useEffect(() => {
    let now = new Date();
    let currentYear = now.getFullYear().toString();
    let currentYearBD = (now.getFullYear() + 543).toString();
    let currentMonth = (now.getMonth() + 1).toString();
    let currentMonthNumber = (now.getMonth() + 1);
    currentMonth = currentMonth.length < 2 ? "0" + currentMonth : currentMonth;
    setCurrentMonth(currentMonth);

    if(currentMonth && currentMonth != ""){
      let monthLabel = DateHelper.getMonthName(currentMonth);
      setCurrentMonthLabel(monthLabel + " " + currentYearBD);
    }
    (
       async () => {
            let locationOptionList = await getLocation(bussinessId);
            setLocationOptions(locationOptionList);
            let currentRawData = await getCurrentMonthDashboardRawData(bussinessId);
            setCurrMonthDashboardRawData(currentRawData);
       }
    )();
    setSearchCriteria(
        {
            from: {year:currentYear, month: currentMonth},
            to: {year: currentYear, month: currentMonth},
            // locationList: ["ALL"]
        }
    );
  }, []);

  // useEffect(() => {
  //   if(currentMonth && currentMonth != ""){
  //     let monthLabel = DateHelper.getMonthName("" + currentMonth);
  //     setCurrentMonthLabel(monthLabel);
  //   }
  // }, [currentMonth])

  useEffect(() => {
    (async() => {
        // console.log("searchCriteria useEffect");
        if(searchCriteria != null){
            try{
                let rawData: DashboardRawData[] = await getDashboardRawData(bussinessId, searchCriteria);
                // console.log("RawData: ", rawData);
                setDashboardRawData(rawData);
            }catch(ex){
                setIsLoading(false);
            }
        }
    })()
  }, [searchCriteria])

  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [dashboardRawData])
  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [currMonthDashboardRawData])
  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [searchLocationList])
  useEffect(() => {
    if(dashboardData){
        setTextChartData(dashboardData.textDashboard);
        setSummaryChartData(dashboardData.summaryDashboard);
        setTopChartData(dashboardData.topDashboard);
    }
    setIsLoading(false);
  }, [dashboardData])
  // Export Image
  const downloadImage = () => {
    chart?.downloadImage();
  };

  // Get chart base64 string
  const toDataURL = () => {
    console.log(chart?.toDataURL());
  };

  const onSearch = (criteria: SearchCriteria) => {
    // console.log("Transport criteria: ", criteria);
    // let searchDatePeriod: PeriodDate = DateHelper.convertFromTo(criteria.year, criteria.fromMonth, criteria.toMonth);
    // console.log("searchPeriod: ", searchDatePeriod);
    setIsLoading(true);
    setSearchCriteria(criteria);
  }

  const onLocationChange = (locationList: string[]) => {
    setSearchLocationList(locationList);
  }

  return (
    <div>
      {/* <button type="button" onClick={downloadImage} style={{ marginRight: 24 }}>
        Export Image
      </button>
      <button type="button" onClick={toDataURL}>
        Get base64
      </button> */}
        <TextChart chartData={textChartData} isLoading={isLoading} headerLabel={currentMonthLabel}></TextChart>
        <div className={styles['secondSection']}>
            <div className={styles['summaryChartContainer']}>
                <SummaryChart data={summaryChartData} config={config} isLoading={isLoading}></SummaryChart>
            </div>
            <div className={styles['searchCriteriaContainer']}>
                <SearchChart 
                    onSearch={onSearch} 
                    searchCriteria={searchCriteria} 
                    onLocationChange={onLocationChange}
                    isLoading={isLoading}
                    locationOptions={locationOptions}></SearchChart>
            </div>
        </div>
        <div className={styles['thirdSection']}>
            <TopChart data={topChartData} searchCriteria={searchCriteria} isLoading={isLoading}></TopChart>
        </div>
    </div>
  );
}

export default TransportTab;
