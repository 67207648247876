import styles from './TopChart.module.css';
import { Bar } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import { SearchCriteria, SearchLocation } from '../model/SearchCriteria';
import { IChartData, ITopChartData } from '../../../services/DashboardService';
import { Spin } from 'antd';
import * as nh from '../../../helper/NumberHelper'

interface IParams {
    searchCriteria: SearchCriteria | null;
    data: ITopChartData;
    isLoading: boolean;
}
const TopChart = (props: IParams) => {
    const config = {
        // data,
        xField: 'value',
        yField: 'label',
        dodgePadding: 4,
        intervalPadding: 20,
        tooltip: {
            customContent: (title, items) => {
                if(items && items.length > 0){
                    return (
                        <div style={{display: 'flex', padding: 5}}>
                            {title}: {nh.numberShowFormat(items[0].data.value, 2)}
                        </div>
                    )
                }
            }
        }
    };

    const tootipNoDotConfig = {
        ...config,
        tooltip: {
            customContent: (title, items) => {
                if(items && items.length > 0){
                    return (
                        <div style={{display: 'flex', padding: 5}}>
                            {title}: {nh.numberShowFormat(items[0].data.value, 0)}
                        </div>
                    )
                }
            }
        }
    }

    const [chartData, setChartData] = useState<ITopChartData>(props.data);
    const [incomeChartData, setIncomeChartData] = useState<IChartData[]>([]);
    const [paidChartData, setPaidChartData] = useState<IChartData[]>([]);
    const [overdueChartData, setOverdueChartData] = useState<IChartData[]>([]);
    const [rentedChartData, setRentedChartData] = useState<IChartData[]>([]);
    const [freeRoomChartData, setFreeRoomChartData] = useState<IChartData[]>([]);
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(props.searchCriteria);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIncomeChartData(chartData.income.filter((data, index) => {return index < 10}));
        setPaidChartData(chartData.paid.filter((data, index) => {return index < 10}));
        setOverdueChartData(chartData.overdue.filter((data, index) => {return index < 10}));
        setRentedChartData(chartData.rented.filter((data, index) => {return index < 10}));
        setFreeRoomChartData(chartData.freeRoom.filter((data, index) => {return index < 10}));
    }, []);

    useEffect(() => {
        setSearchCriteria(props.searchCriteria);
    }, [props.searchCriteria]);
    useEffect(() => {
        setChartData(props.data);
        setIncomeChartData(props.data.income.filter((data, index) => {return index < 10}));
        setPaidChartData(props.data.paid.filter((data, index) => {return index < 10}));
        setOverdueChartData(props.data.overdue.filter((data, index) => {return index < 10}));
        setRentedChartData(props.data.rented.filter((data, index) => {return index < 10}));
        setFreeRoomChartData(props.data.freeRoom.filter((data, index) => {return index < 10}));
    }, [props.data]);
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    const renderBody = (data: IChartData[], colorCode: string) => {
        if(!isLoading){
        // if(true){
            return (<Bar data={data} {...config} color={colorCode} />)
        }else{
            return (
                <Spin size="large" tip="กำลังโหลดข้อมูล"/>
            );
        }
    }

    const renderBodyNoComma = (data: IChartData[], colorCode: string) => {
        if(!isLoading){
        // if(true){
            return (<Bar data={data} {...tootipNoDotConfig} color={colorCode} />)
        }else{
            return (
                <Spin size="large" tip="กำลังโหลดข้อมูล"/>
            );
        }
    }
    return (
        <div className={styles['mainContainer']}>
            <div className={styles['headerLabel']}>
                TOP 10
            </div>
            <div className={styles['chartsContainerTable']}>
                <div className={styles['chartContainerRow']}>
                    <div className={styles['chartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}>รายได้ทั้งหมด</div>
                        <div className={styles['chartBody']}>{renderBody(incomeChartData, "#E9E9E9")}</div>
                        {/* <div><Bar data={incomeChartData} {...config} color={"#E9E9E9"}/></div> */}
                        {/* <div><Spin size="large" tip="กำลังโหลดข้อมูล"/></div> */}
                    </div>
                    <div className={styles['chartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}>รับชำระแล้ว</div>
                        <div className={styles['chartBody']}>{renderBody(paidChartData, "#AAFD83")}</div>
                        {/* <div><Bar data={paidChartData} {...config} color={"#AAFD83"}/></div> */}
                        {/* <div><Spin size="large" tip="กำลังโหลดข้อมูล"/></div> */}
                    </div>
                    <div className={styles['chartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}>ค้างรับชำระ</div>
                        <div className={styles['chartBody']}>{renderBody(overdueChartData, "#FD8383")}</div>
                        {/* <div><Bar data={overdueChartData} {...config} color={"#FD8383"}/></div> */}
                        {/* <div><Spin size="large" tip="กำลังโหลดข้อมูล"/></div> */}
                    </div>
                </div>
                <div className={styles['chartContainerRow']}>
                    <div className={styles['chartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}>ห้องที่มีการเช่า</div>
                        <div className={styles['chartBody']}>{renderBodyNoComma(rentedChartData, "#73AAFB")}</div>
                        {/* <div><Bar data={rentedChartData} {...config} color={"#73AAFB"}/></div> */}
                    </div>
                    <div className={styles['chartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}>ห้องว่าง</div>
                        <div className={styles['chartBody']}>{renderBodyNoComma(freeRoomChartData, "#F5FB73")}</div>
                        {/* <div><Bar data={freeRoomChartData} {...config} color={"#F5FB73"}/></div> */}
                    </div>
                    <div className={styles['inActiveChartContainerCell']}>
                        <div className={styles['chartHeaderLabel']}></div>
                        <div className={styles['chartBody']}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopChart;