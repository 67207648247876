import React, { useEffect, useState } from "react";
import { Column } from '@ant-design/charts';
import styles from './SummaryChart.module.css';
import { IChartData, ISummaryChartData } from "../../../services/DashboardService";
import { Spin } from 'antd';
import * as nh from '../../../helper/NumberHelper';

interface IParams {
    config: any;
    data: ISummaryChartData;
    isLoading: boolean;
}
const SummaryChart = (props: IParams) => {
    const defaultConfig = {
        xField: 'label',
        yField: 'value',
        autoFit: true,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };
    const tooltip = {
        tooltip: {
            customContent: (title, items) => {
                if(items && items.length > 0){
                    let newComponent = [];
                    // console.log("items: ", items);
                    newComponent.push(
                        <div style={{display: 'flex', padding: 5}}>
                            <b>{title}</b>
                        </div>
                    );
                    for(let i = 0; i < items.length; i++){
                        let item = items[i];
                        newComponent.push(
                            <div style={{display: 'flex', padding: 5, alignItems: 'center'}}>
                                <div style={{width: 10, height: 10, backgroundColor: item.color, marginRight: 5}}></div>{item.name}: {nh.numberShowFormat(item.data.value, 2)}
                            </div>
                        );
                    }
                    return newComponent;
                }
            }
        }
    }
    const [configState, setConfigState] = useState({...defaultConfig});
    const [chart, setChart] = useState<any>();
    const [data, setData] = useState<IChartData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setConfigState({...defaultConfig, ...props.config});
    }, [props.config]);

    useEffect(() => {
        setData(props.data.summaryDataList);
    }, [props.data]);
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    const renderBody = () => {
        if(!isLoading){
            return (<Column className={styles['chart']} data={data} {...configState} {...tooltip} onReady={(chartInstance) => {setChart(chartInstance);}} />)
        }else{
            return (
                <Spin className={styles['chart']} size="large" tip="กำลังโหลดข้อมูล"/>
            );
        }
    }
    return (
        <div className={styles['mainContainer']}>
            {renderBody()}
        </div>
    );
}

export default SummaryChart;