import React from "react";
import { Line, Bar, Column } from '@ant-design/charts';import { Tabs } from 'antd';
import TransportTab from "./tabs/Transport";
import LeasingTab from "./tabs/Leasing";

function Dashboard() {
  const { TabPane } = Tabs;

  function callback(key: any) {
    console.log(key);
  }
  
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="ขนส่ง" key="1">
        <TransportTab />
      </TabPane>
      <TabPane tab="ลิสซิ่ง" key="2">
        <LeasingTab />
      </TabPane>
    </Tabs>
  
  );
}

export default Dashboard;
