import config from "../constants/config";
import { SearchCriteria } from "../views/dashboard/model/SearchCriteria";
import http from '../lib/http';
import axios from 'axios';


import * as SampleData from './SampleData'
import { getMonthName } from "../helper/DateHelper";

const serviceUrl = `${config.urlRoot}`;

export enum IncomeType{
    TOTAL_INCOME = "รายได้ทั้งหมด",
    PAID = "ชำระเงินแล้ว",
    OVERDUE = "ค้างชำระ"
}
export interface PropertyGroup {
    id: number;
    name: string;
    scopeId: number;
    scopeName: string;
    totalRooms: number;
}

export interface IResponseReports {
   scopeId: number,
   propGrpId: number,
   scopeName: string,
   propGroupName: string,
   allRoomQty: number,
   onRentRoomQty: number,
   emptyRoomQty: number,
   totalAmt: number,
   payAmt: number,
   notPayAmt: number,
   year: string,
   month: string,
}

export interface IResponsePropertyGroup {
    id: number;
    name: string;
}

export interface IDashboardRawData {
    year: string;
    month: string;
    propertyData: PropertyGroup;
    totalIncome: number;
    paid: number;
    rented: number;
    emptyRoomQty: number;
    overdue: number;
}

export class DashboardRawData implements IDashboardRawData{
    year: string;
    month: string;
    propertyData: PropertyGroup;
    totalIncome: number;
    paid: number;
    rented: number;
    emptyRoomQty: number;
    overdue: number;

    constructor(rawData: IDashboardRawData){
        this.year = rawData.year;
        this.month = rawData.month;
        this.propertyData = rawData.propertyData;
        this.paid = rawData.paid;
        this.totalIncome = rawData.totalIncome;
        this.rented = rawData.rented;
        this.emptyRoomQty = rawData.emptyRoomQty;
        this.overdue = rawData.overdue;
    }

    // overdue = () => {return this.totalIncome - this.paid;}
    // freeRooms = () => {return this.propertyData.totalRooms - this.rented}
}
export interface IDashboardData {
    textDashboard: ITextChartData;
    summaryDashboard: ISummaryChartData;
    topDashboard: ITopChartData;
}
export interface IChartData {
    label: string,
    type: string,
    value: number,
}
export interface ITextChartData {
    totalIncome: number;
    paid: number;
    overDue: number;

    totalRoom: number;
    rentalRoom: number;
    emptyRoom: number;
}
export interface ISummaryChartData{
    summaryDataList: IChartData[];
}
export interface ITopChartData{
    income: IChartData[];
    paid: IChartData[];
    overdue: IChartData[];
    rented: IChartData[];
    freeRoom: IChartData[];
}
export interface IInputOption {
    label: string;
    value: string;
}

export const getTextDashboard = async (searchCriteria: SearchCriteria) => {
    
}

export const getLocation = async (businessId: number) => {
    let url = `${serviceUrl}/propertygroups`;
    let searchCriteria: SearchCriteria = {
        from: {year: "", month: ""},
        to: {year: "", month: ""},
        businessId: businessId
    }
    let res = await axios({
        method: 'POST',
        url: url, 
        data: searchCriteria
    });
    let propertygroups:IResponsePropertyGroup[] = res.data;
    // console.log("propertygroups: ", propertygroups);
    // let pgList = SampleData.samplePropertyGroupList;
    let result: IInputOption[] = propertygroups.map((data) => {
        return {label: data.name, value: data.id.toString()}
    });
    return [{label: 'ทั้งหมด', value: "ALL"}, ...result];
}

export const getDashboardRawData = async (bussinessId: number, searchCriteria: SearchCriteria) => {
    let url = `${serviceUrl}/reports`;
    searchCriteria.businessId = bussinessId;
    // let res = await axios.get(url, {data: searchCriteria});
    // console.log("getDashboardRawData >> searchCriteria: ", searchCriteria);
    let res = await axios({
        method: 'POST',
        url: url, 
        data: searchCriteria
    });
    let dashboardData: IResponseReports[] = res.data;
    // console.log("getDashboardRawData >> dashboardData: ", dashboardData);
    let result: IDashboardRawData[] = dashboardData.map((data) => {
        let newData: IDashboardRawData = {
            year: data.year,
            month: data.month,
            propertyData: {
                id: data.propGrpId,
                name: data.propGroupName,
                scopeId: 0,
                scopeName: "",
                totalRooms: data.allRoomQty,
            },
            totalIncome: data.totalAmt,
            paid: data.payAmt,
            rented: data.onRentRoomQty,
            emptyRoomQty: data.emptyRoomQty,
            overdue: data.notPayAmt
        }
        return newData;
    })
    const mapData = (datas: IDashboardRawData[]) => {
        return datas.map((data) => {
            return new DashboardRawData(data);
        });
    }
    // return [...mapData(group1), ...mapData(group2), ...mapData(group3)];
    return mapData(result);
}

export const getCurrentMonthDashboardRawData = async (bussinessId: number) => {
    let url = `${serviceUrl}/reports`;
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    let currentMonthStr = currentMonth.toString().length < 2 ? `0${currentMonth}` : currentMonth.toString();
    let searchCriteria: SearchCriteria = {
        from: {year: currentDate.getFullYear().toString(), month: currentMonthStr},
        to: {year: currentDate.getFullYear().toString(), month: currentMonthStr},
    }
    searchCriteria.businessId = bussinessId;
    // let res = await axios.get(url, {data: searchCriteria});
    // console.log("getDashboardRawData >> searchCriteria: ", searchCriteria);
    let res = await axios({
        method: 'POST',
        url: url, 
        data: searchCriteria
    });
    let dashboardData: IResponseReports[] = res.data;
    // console.log("getDashboardRawData >> dashboardData: ", dashboardData);
    let result: IDashboardRawData[] = dashboardData.map((data) => {
        let newData: IDashboardRawData = {
            year: data.year,
            month: data.month,
            propertyData: {
                id: data.propGrpId,
                name: data.propGroupName,
                scopeId: 0,
                scopeName: "",
                totalRooms: data.allRoomQty,
            },
            totalIncome: data.totalAmt,
            paid: data.payAmt,
            rented: data.onRentRoomQty,
            emptyRoomQty: data.emptyRoomQty,
            overdue: data.notPayAmt
        }
        return newData;
    })
    const mapData = (datas: IDashboardRawData[]) => {
        return datas.map((data) => {
            return new DashboardRawData(data);
        });
    }
    // return [...mapData(group1), ...mapData(group2), ...mapData(group3)];
    return mapData(result);
}

export const adjustDashboardData = async (rawData: DashboardRawData[], locationList: string[], currRawData: DashboardRawData[]) => {
    let result: IDashboardData = {
        textDashboard: await adjustTextDashboardData(currRawData, locationList),
        summaryDashboard: await adjustSummaryDashboard(rawData, locationList),
        topDashboard: await adjustTopDashboard(rawData, locationList)
    }

    return result;
}

const adjustTextDashboardData = async (rawData: DashboardRawData[], locationList: string[]) => {
    // console.log("locationList: ", locationList);
    let currentMonth = new Date().getMonth() + 1;
    // console.log("currentMonth: ", currentMonth);
    let filterRawData: DashboardRawData[] = rawData.filter((data) => {
        // let findResult = locationList.find((loc) => {
        //     // console.log("loc: ", loc);
        //     return loc != "ALL" && data.propertyData.id == parseInt(loc)
        // });
        // // console.log("findResult: ", findResult);
        // return findResult != null;
        return parseInt(data.month) == currentMonth;
    })
    let result: ITextChartData = {
        totalIncome: 0,
        paid: 0,
        overDue: 0,
        totalRoom: 0,
        rentalRoom: 0,
        emptyRoom: 0,
    };
    // console.log("filterRawData: ", filterRawData);
    filterRawData.forEach((data: DashboardRawData) => {
        // console.log("data forEach: ", data);
        result.totalIncome += data.totalIncome;
        result.paid += data.paid;
        result.overDue += data.overdue;
        result.totalRoom += data.propertyData.totalRooms;
        result.rentalRoom += data.rented;
        result.emptyRoom += data.emptyRoomQty;
    })
    return result;
}

const adjustSummaryDashboard = async(rawData: DashboardRawData[], locationList: string[]) => {
    let filterRawData = rawData.filter((data) => {
        return locationList.find((value) => {return value != "ALL" && parseInt(value) == data.propertyData.id})
    })

    // let filterRawData = rawData;

    let result: ISummaryChartData = {
        summaryDataList: []
    }

    let resultDict: {[key: string]: IChartData} = {}
    console.log("adjustSummaryDashboard", rawData);
    console.log("adjustSummaryDashboard", filterRawData);
    filterRawData.forEach((data, index) => {
        if(!resultDict[data.month + "_TI"]) {
            resultDict[data.month + "_TI"] = {
                label: getMonthName(data.month),
                value: 0,
                type: IncomeType.TOTAL_INCOME,
            }
        }
        if(!resultDict[data.month + "_PD"]) {
            resultDict[data.month + "_PD"] = {
                label: getMonthName(data.month),
                value: 0,
                type: IncomeType.PAID,
            }
        }
        if(!resultDict[data.month + "_OD"]) {
            resultDict[data.month + "_OD"] = {
                label: getMonthName(data.month),
                value: 0,
                type: IncomeType.OVERDUE,
            }
        }
        resultDict[data.month + "_TI"].value += data.totalIncome;
        resultDict[data.month + "_PD"].value += data.paid;
        resultDict[data.month + "_OD"].value += data.overdue > 0 ? data.overdue : 0;
        // result.summaryDataList.push({
        //     label: getMonthName(data.month),
        //     value: data.totalIncome,
        //     type: IncomeType.TOTAL_INCOME,
        // });
        // result.summaryDataList.push({
        //     label: getMonthName(data.month),
        //     value: data.paid,
        //     type: IncomeType.PAID,
        // });
        // result.summaryDataList.push({
        //     label: getMonthName(data.month),
        //     value: data.overdue,
        //     type: IncomeType.OVERDUE,
        // });
        // let findData = dList.find((d) => {
        //     return d.label.localeCompare(data.month)
        // })
    })
    for(let key in resultDict){
        resultDict[key].value = Math.round(resultDict[key].value * 100) / 100;
        result.summaryDataList.push(resultDict[key]);
    }
    
    return result;
}

const adjustTopDashboard = async(rawData: DashboardRawData[], locationList: string[]) => {
    // let filterRawData = rawData.filter((data) => {
    //     return locationList.find((value) => {return value != "ALL" && parseInt(value) == data.propertyData.id})
    // })
    let filterRawData = rawData;
    let incomeDict: {[key: string]: IChartData} = {};
    let freeRoomDict: {[key: string]: IChartData} = {};
    let overdueDict: {[key: string]: IChartData}= {};
    let paidDict: {[key: string]: IChartData}= {};
    let rentedDict: {[key: string]: IChartData}= {};

    filterRawData.forEach((data) => {
        let key = `propGrp_${data.propertyData.id.toString()}`;
        manageChartDataDict(incomeDict, key, data.propertyData.name, data.totalIncome);
        manageChartDataDict(freeRoomDict, key, data.propertyData.name, data.emptyRoomQty);
        manageChartDataDict(overdueDict, key, data.propertyData.name, data.overdue);
        manageChartDataDict(paidDict, key, data.propertyData.name, data.paid);
        manageChartDataDict(rentedDict, key, data.propertyData.name, data.rented);
    })
    let result: ITopChartData = {
        income: convertDict2Array(incomeDict),
        freeRoom: convertDict2Array(freeRoomDict),
        overdue: convertDict2Array(overdueDict),
        paid: convertDict2Array(paidDict),
        rented: convertDict2Array(rentedDict)
    }

    return result;
}

const manageChartDataDict = (dict: {[key: string]: IChartData}, key: string, label: string, value: number) => {
    if(!dict[key]){
        dict[key] = {
            label: label,
            value: 0,
            type: ''
        }
    }
    dict[key].value += value;
}

const convertDict2Array = (dict: {[key: string]: IChartData}) => {
    let result: IChartData[] = [];
    for(let key in dict){
        result.push(dict[key]);
    }
    result = result.sort((a, b) => {
        return b.value - a.value;
    })
    return result;
}