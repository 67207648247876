import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector,
  } from "react-redux";
  import type { TypedUseSelectorHook } from "react-redux";
  import type { ThunkAction } from "redux-thunk";
  import { configureStore } from "@reduxjs/toolkit";
  import type { Action, AnyAction } from "@reduxjs/toolkit";
  import rootReducer from "./rootReducer";
  
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
  });
  
  export type RootState = ReturnType<typeof store.getState>;
  
  export type AppDispatch = typeof store.dispatch;
  
  export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;
  
  export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
  
  export const useAppDispatch = () => useReduxDispatch<AppDispatch>();
  
  export default store;
  