import React, { useState } from "react";
import { Outlet } from 'react-router-dom';

const SimpleLayout = () => {
    return (
        <div
          style={{
            width: "100%",
            padding: "0px 50px 10px 50px",
            backgroundColor: "#F1F2F7",
          }}
        >
          {/* <div>{props.children}</div> */}
          <div><Outlet></Outlet></div>
        </div>
    )
}

export default SimpleLayout