import React, { useEffect, useState } from "react";
import styles from './TextChart.module.css'
import * as NumberHelper from '../../../helper/NumberHelper';
import { ITextChartData } from "../../../services/DashboardService";
import { Spin } from 'antd';

interface IParams {
    chartData: ITextChartData;
    isLoading: boolean;
    headerLabel: string;
}

const TextChart = (props: IParams) => {
    const [chartData, setChartData] = useState(props.chartData);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [headerLabel, setHeaderLabel] = useState<string>("Test");

    useEffect(() => {
        setChartData(props.chartData);
    }, [props.chartData]);
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])
    useEffect(() => {
        setHeaderLabel(props.headerLabel);
    }, [props.headerLabel])

    const renderData = (data: any) => {
        if(!isLoading){
            return data
        }else{
            return (
                <Spin size="small"/>
            );
        }
    }

    return (
        <div className={styles["mainContainer"]}>

            <div className={styles["row"]}>
                <div className={styles["col"]}>
                    <h3 style={{marginLeft: 10}}>ประจำเดือน: <span style={{color: "red"}}>{headerLabel}</span></h3>
                </div>
            </div>
            <div className={styles["divideLine"]} style={{marginBottom: 10}}/>
            <div className={styles["row"]}>
                <div className={styles["col"]}>
                    <div className={styles["dataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <h3>รายได้ทั้งหมด</h3>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.totalIncome))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.totalIncome)}</span> */}
                        </div>
                    </div>
                </div>  
                <div className={styles["col"]}>
                    <div className={styles["dataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <h3>ชำระเงินแล้ว</h3>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.paid))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.paid)}</span> */}
                        </div>
                    </div>
                </div>  
                <div className={styles["col"]}>
                    <div className={styles["dataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <h3>ค้างชำระ</h3>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.overDue))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.overDue)}</span> */}
                        </div>
                    </div>
                </div>  
            </div>
            <div className={styles["divideLine"]}/>
            <div className={styles["row"]}>
                <div className={styles["col"]}>
                    <div className={styles["roomDataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <span>จำนวนห้องทั้งหมด</span>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.totalRoom, 0))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.totalRoom, 0)}</span> */}
                        </div>
                    </div>
                </div>  
                <div className={styles["col"]}>
                    <div className={styles["roomDataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <span>ห้องที่มีการเช่า</span>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.rentalRoom, 0))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.rentalRoom, 0)}</span> */}
                        </div>
                    </div>
                </div>  
                <div className={styles["col"]}>
                    <div className={styles["roomDataContainer"]}>
                        <div className={styles["labelContainer"]}>
                            <span>ห้องว่าง</span>
                        </div>
                        <div className={styles["valueContainer"]}>
                            <span>{renderData(NumberHelper.numberShowFormat(chartData.emptyRoom, 0))}</span>
                            {/* <span>{NumberHelper.numberShowFormat(chartData.emptyRoom, 0)}</span> */}
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    );
}

export default TextChart;