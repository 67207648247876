import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useRoutes,
} from "react-router-dom";
import Home from "./views/Home";
import LoginForm from "./views/LoginForm";
import ModernAppLayout from "./layouts/ModernAppLayout";
import Dashboard from "./views/dashboard/Dashboard";
import SimpleLayout from "./layouts/SimpleLayout";
// import "./app.css"
// import AppRoute from "./views/AppRoute";

const App: React.FC = () => {
  const mainRoutes = {
    path: '/',
    element: <LoginForm />,
    // children: [
    //   {path: '*', element: <Navigate to='/404' />},
    //   // {path: '/', element: <MainView />},
    //   // {path: '404', element: <PageNotFoundView />},
    //   // {path: 'account', element: <Navigate to='/account/list' />},
    // ],
  };

  const accountRoutes = {
    path: 'app',
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // { path: "tasks", element: <DashboardTasks /> },
    ],
  };

  const routing = useRoutes([mainRoutes, accountRoutes]);
  return (
    // <Home/>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Home/>} />
    //     <Route path="/login" element={<Login/>} />
    //   </Routes>
    // </Router>
    <>{routing}</>
  );
};

export default App;
