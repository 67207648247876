import React, { useEffect, useState } from "react";
import { Line, Bar, Column } from '@ant-design/charts';
import TextChart from "../component/TextChart";
import SummaryChart from "../component/SummaryChart";
import styles from '../Dashboard.module.css'
import SearchChart from "../component/SearchChart";
import { SearchCriteria, SearchLocation } from "../model/SearchCriteria";
import TopChart from "../component/TopChart";
import { PeriodDate } from "../../../helper/DateHelper";
import * as DateHelper from "../../../helper/DateHelper";
import { IDashboardData, DashboardRawData, getDashboardRawData, ISummaryChartData, ITextChartData, ITopChartData, adjustDashboardData, IInputOption, getLocation, getCurrentMonthDashboardRawData } from "../../../services/DashboardService";

function TransportTab() {
  const config = {
    // data,
    // width: 800,
    // height: 400,
    autoFit: true,
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    // point: {
    //   size: 5,
    //   shape: 'diamond',
    // },
    // label: {
    //     position: 'top',
    //     style: {
    //         fill: '#aaa',
    //     },
    // },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  let chart: any;

  const bussinessId = 1;

  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(null);
  const [dashboardData, setDashboardData] = useState<IDashboardData>();
  const [dashboardRawData, setDashboardRawData] = useState<DashboardRawData[]>();
  const [currMonthDashboardRawData, setCurrMonthDashboardRawData] = useState<DashboardRawData[]>([]);
  const [searchLocationList, setSearchLocationList] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<IInputOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<string>("01");
  const [currentMonthLabel, setCurrentMonthLabel] = useState<string>("");
  const [currentYear, setCurrentYear] = useState<string>("");

  const [textChartData, setTextChartData] = useState<ITextChartData>({
        totalIncome: 0,
        paid: 0,
        overDue: 0,
        totalRoom: 0,
        rentalRoom: 0,
        emptyRoom: 0,
  });
  const [topChartData, setTopChartData] = useState<ITopChartData>({
      income: [],
      paid: [],
      overdue: [],
      rented: [],
      freeRoom: []
  });
  const [summaryChartData, setSummaryChartData] = useState<ISummaryChartData>({summaryDataList: []});

  useEffect(() => {
    let now = new Date();
    let currentYear = now.getFullYear().toString();
    let currentYearBD = (now.getFullYear() + 543).toString();
    let currentMonth = (now.getMonth() + 1).toString();
    currentMonth = currentMonth.length < 2 ? "0" + currentMonth : currentMonth;
    let currentMonthNumber = (now.getMonth() + 1);
    // setCurrentMonth(currentMonth);
    // setCurrentYear(currentYearBD);

    if(currentMonth && currentMonth != ""){
      let monthLabel = DateHelper.getMonthName(currentMonth);
      setCurrentMonthLabel(monthLabel + " " + currentYearBD);
    }
    setSearchCriteria(
        {
            from: {year:currentYear, month: currentMonth},
            to: {year: currentYear, month: currentMonth},
            // locationList: ["ALL"]
        }
    );
    (
       async () => {
            let locationOptionList = await getLocation(bussinessId);
            setLocationOptions(locationOptionList);
            let currentRawData = await getCurrentMonthDashboardRawData(bussinessId);
            setCurrMonthDashboardRawData(currentRawData);
       }
    )();
  }, []);

  // useEffect(() => {
  //   if(currentMonth && currentMonth != ""){
  //     let monthLabel = DateHelper.getMonthName("" + currentMonth);
  //     setCurrentMonthLabel(monthLabel);
  //   }
  // }, [currentMonth])

  useEffect(() => {
    (async() => {
        console.log("searchCriteria useEffect");
        if(searchCriteria != null){
            try{
                let rawData: DashboardRawData[] = await getDashboardRawData(bussinessId, searchCriteria);
                // console.log("RawData: ", rawData);
                setDashboardRawData(rawData);
            }catch(ex){
                setIsLoading(false);
            }
        }
    })()
  }, [searchCriteria])

  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [dashboardRawData])

  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [currMonthDashboardRawData])
  useEffect(() => {
    (
        async () => {
            if(dashboardRawData){
                let result = await adjustDashboardData(dashboardRawData, searchLocationList, currMonthDashboardRawData);
                // console.log("result: ", result);
                setDashboardData(result);
            }
        }
    )()
  }, [searchLocationList])
  useEffect(() => {
    if(dashboardData){
        setTextChartData(dashboardData.textDashboard);
        setSummaryChartData(dashboardData.summaryDashboard);
        setTopChartData(dashboardData.topDashboard);
    }
    setIsLoading(false);
  }, [dashboardData])
  // Export Image
  const downloadImage = () => {
    chart?.downloadImage();
  };

  // Get chart base64 string
  const toDataURL = () => {
    console.log(chart?.toDataURL());
  };

  const onSearch = (criteria: SearchCriteria) => {
    console.log("Transport criteria: ", criteria);
    // let searchDatePeriod: PeriodDate = DateHelper.convertFromTo(criteria.year, criteria.fromMonth, criteria.toMonth);
    // console.log("searchPeriod: ", searchDatePeriod);
    setIsLoading(true);
    setSearchCriteria(criteria);
  }

  const onLocationChange = (locationList: string[]) => {
    setSearchLocationList(locationList);
  }

  return (
    <div>
      {/* <button type="button" onClick={downloadImage} style={{ marginRight: 24 }}>
        Export Image
      </button>
      <button type="button" onClick={toDataURL}>
        Get base64
      </button> */}
        <TextChart chartData={textChartData} isLoading={isLoading} headerLabel={currentMonthLabel}></TextChart>
        <div className={styles['secondSection']}>
            <div className={styles['summaryChartContainer']}>
                <SummaryChart data={summaryChartData} config={config} isLoading={isLoading}></SummaryChart>
            </div>
            <div className={styles['searchCriteriaContainer']}>
                <SearchChart 
                    onSearch={onSearch} 
                    searchCriteria={searchCriteria} 
                    onLocationChange={onLocationChange}
                    isLoading={isLoading}
                    locationOptions={locationOptions}></SearchChart>
            </div>
        </div>
        <div className={styles['thirdSection']}>
            <TopChart data={topChartData} searchCriteria={searchCriteria} isLoading={isLoading}></TopChart>
        </div>
    </div>
  );
}

export default TransportTab;
